import React, { useState } from "react"
import styled from "styled-components"
import toast from "react-hot-toast"
import axios from "axios"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPageStyles = styled.main`
  h1 {
    padding: 3rem 0 1rem;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }

  h3 {
    padding-bottom: 2rem;
    max-width: 800px;
    text-align: center;
    margin: 0 auto;
    border-bottom: 1px solid #000;
  }

  .form-row {
    display: flex;
    justify-content: space-between;

      .contact-form {
        font-family: "prompt",Arial,Helvetica,sans-serif;
        width: 48%;
        padding: 2em;
        margin: 0 auto 3em;
        display: flex;
        flex-direction: column;
        align-items: center;

        .hidden {
          display: none;
        }

        h2, h3, h4 {
          margin-bottom: 0.5em;
          text-transform: capitalize;
        }

        .form-field {
          display: flex;
          flex-direction: column;
          width: 100%;
          max-width: 500px;
          margin: 0 auto 1em;

          input, textarea {
            padding: 10px;
            font-size: 1.1em;
            font-family: "raleway",Arial,Helvetica,sans-serif;
          }
        }

        button {
          background: #000;
          color: #fff;
          border-radius: 6px;
          border: none;
          padding: 10px;
          font-size: 1.1em;
          width: 100%;
          max-width: 500px;
          margin: 1em auto;
          font-size: 1.2rem;
          cursor: pointer;
          transition: background 0.2s;
          text-decoration: none;
          transition: all 0.5s;

          &:hover {
            background: rgba(0,0,0,0);
            color: #000;
            border: 3px solid #000;
            transition: all 0.3s;
          }

          &:disabled {
            background: rgba(0,0,0,0);
            color: #000;
            border: 3px solid #000;
            cursor: not-allowed;
          }
        }
      }
  }

  @media (max-width: 800px) {
    .contact-form {
      width: 75%!important;
    }
  }
`

export default function ContactPage({ data, location }) {
  const siteTitle = "Contact Us"
  const [isSending, setIsSending] = useState(false)
  const [honey, setHoney] = useState(undefined)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')

  const handleSubmit = async (event) => {
    event.preventDefault();

    const body = {
      honey,
      name,
      email,
      subject,
      message
    };

    if (body.honey !== undefined) {
      toast.error('Your request cannot be submitted. Please refresh and try again.')
    } else {
      try {
        setIsSending(true);
        const response = await axios.post(`/.netlify/functions/contact`, body);
        toast.success('Your response was sucessfully submitted!')
      } catch(ex) {
        console.error(ex);
        toast.error("Error sending your response!")
      }

      setIsSending(false);
    }
  }

  return (
    <Layout location={location} title={siteTitle}>
       <SEO
        title={siteTitle}
        keywords={[]}
      />
      <ContactPageStyles>
        <h1>Contact Us!</h1>
        <h3>Call us at <a href={`tel:248-714-8186`}>(248) 714-8186</a> or fill out the form below to get in touch!</h3>
        <div className="form-row">
          <form 
              method="post"
              name="Contact Form"
              className="contact-form"
              netlify-honeypot="hpfield"
              onSubmit={handleSubmit}
          >
              <input className="hidden" name="hpfield" value={honey} onChange={(e) => setHoney(e.target.value)} />
              <div className="form-field">
                  <label htmlFor="name">Your Name</label>
                  <input id="name" type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} required />
              </div> 
              <div className="form-field">
                  <label htmlFor="email">Your Email</label>
                  <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
              </div>
              <div className="form-field">
                  <label htmlFor="subject">Subject</label>
                  <input type="text" name="subject" value={subject} onChange={(e) => setSubject(e.target.value)} required />
              </div>
              <div className="form-field">
                  <label htmlFor="message">Your Message</label>
                  <textarea name="message" rows="3" value={message} onChange={(e) => setMessage(e.target.value)} required></textarea>
              </div>
              <button type="submit" disabled={isSending}>
                {isSending ? 'Sending...' : 'Send'}
              </button>
          </form>
        </div>
      </ContactPageStyles>
    </Layout>
  );
}
